/* .container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
} */
.headingSub {

  font-size: large;
  color: #3d4144 !important;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.centerText {
  padding: 50px 0;
  margin: 0;
  text-align: center;
}

.corporate {
  padding: 50px 0;
  margin: 0;
  text-align: center;
  color: white;
}

.pagetextleft {
  color: #6c757d !important;
  font-size: 1.1rem;
}

.btnlink {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
