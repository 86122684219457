.page{   
    padding-top: "6em";
    font-family: "sans-serif";
}
header{
font-size: 50px;
color: #1a3352;
}
.list{
  display: list-item;
}
